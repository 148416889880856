import { createSlice } from '@reduxjs/toolkit';

import {
  CategoryType,
  CityType,
  CountryType,
  CurrencyType,
  SelectType,
  TagType,
  VacancyType,
} from 'utils/types';

import { vacancyStatusOptions } from 'utils/constants';

const getInitialStore = () => ({
  vacanciesList: [] as VacancyType[],
  vacancy: null as unknown as VacancyType,
  candidatesPagination: {
    paginationPerPage: 20,
    paginationPage: 1,
    hasMore: true,
    count: 0,
  },
  count: 0,
  sortBy: 'createdAt',
  sortDirection: 'reverse',
  sortSelect: { value: ['createdAt', 'reverse'], label: 'latest' },
  paginationPerPage: 20,
  paginationPage: 1,
  hasMore: true,
  isShareOpen: false,
  sharedVacancyId: null,
  isLoadingCandidates: false,
  filter: {
    country: null as unknown as CountryType,
    city: null as unknown as CityType,
    category: null as unknown as CategoryType,
    subcategory: null as unknown as CategoryType,
    salary: [0, 600000],
    currency: null as unknown as CurrencyType,
    status: [vacancyStatusOptions[0]] as SelectType[],
    tags: null as unknown as TagType,
    search: '',
    manager: null as unknown as SelectType[],
  },
});

const initialStore = getInitialStore();

export type StoreType = typeof initialStore;

export const sliceName = 'vacanciesList';

const vacanciesListSlice = createSlice({
  name: sliceName,
  initialState: getInitialStore(),
  reducers: {
    setVacanciesList: (store, { payload }) => ({
      ...store,
      vacanciesList: [
        ...(store.vacanciesList as VacancyType[]),
        ...payload.vacancies,
      ],
      count: payload.count,
    }),
    setVacancyCandidates: (store, { payload }) => {
      store.vacancy = {
        ...store.vacancy,
        candidates: [
          ...(store.vacancy?.candidates || []),
          ...payload.candidates,
        ],
        candidateCount: payload.count,
      };
    },
    setVacancy: (store, { payload }) => ({
      ...store,
      vacancy: payload,
    }),
    updateOneVacancy: (store, { payload }) => ({
      ...store,
      vacanciesList: store.vacanciesList!.map((vacancy: VacancyType) =>
        vacancy!.id === payload.id
          ? { ...vacancy, ...(payload as VacancyType) }
          : vacancy,
      ),
      vacancy:
        store.vacancy !== null && store.vacancy?.id === payload.id
          ? { ...store.vacancy, ...payload }
          : store.vacancy,
    }),
    updateSearch: (store, { payload }) => ({
      ...store,
      filter: {
        ...store.filter,
        search: payload,
      },
    }),
    updateSort: (store, { payload: { value, label } }) => ({
      ...store,
      sortBy: value[0],
      sortDirection: value[1],
      sortSelect: {
        value,
        label,
      },
    }),
    updateFilter: (store, { payload }) => ({
      ...store,
      filter: {
        ...store.filter,
        [payload.name]: payload.value,
      },
    }),
    updatePage: (store) => ({
      ...store,
      paginationPage: store.paginationPage + 1,
    }),
    resetPage: (store) => ({
      ...store,
      paginationPage: 1,
    }),
    updateCandidatesPage: (store) => {
      store.candidatesPagination = {
        ...store.candidatesPagination,
        paginationPage: store.candidatesPagination.paginationPage + 1,
      };
    },
    resetCandidatesPage: (store) => {
      store.candidatesPagination = {
        ...store.candidatesPagination,
        paginationPage: 1,
      };
    },
    clearFilter: (store) => ({
      ...store,
      filter: getInitialStore().filter,
    }),
    resetVacanciesList: (store) => ({
      ...store,
      vacanciesList: [],
      count: 0,
    }),
    resetCandidatesList: (store) => {
      store.candidatesPagination = {
        ...store.candidatesPagination,
        count: 0,
      };

      if (store.vacancy) {
        store.vacancy.candidates = [];
      }
    },
    isHasMore: (store, { payload }) => ({
      ...store,
      hasMore: payload >= store.paginationPerPage,
    }),
    isHasMoreCandidates: (store, { payload }) => {
      store.candidatesPagination = {
        ...store.candidatesPagination,
        hasMore: payload >= store.candidatesPagination.paginationPage,
      };
    },
    setLoadingCandidates: (store, { payload }) => ({
      ...store,
      isLoadingCandidates: payload,
    }),
    toggleShareModal: (store, { payload }) => ({
      ...store,
      isShareOpen: !store.isShareOpen,
      sharedVacancyId: payload,
    }),
  },
});

export const {
  setVacanciesList,
  setVacancy,
  resetVacanciesList,
  updateSearch,
  updateSort,
  updateFilter,
  clearFilter,
  updatePage,
  resetPage,
  isHasMore,
  updateOneVacancy,
  toggleShareModal,
  updateCandidatesPage,
  resetCandidatesPage,
  resetCandidatesList,
  setVacancyCandidates,
  isHasMoreCandidates,
  setLoadingCandidates,
} = vacanciesListSlice.actions;

export default vacanciesListSlice.reducer;
