import axios from 'api/axios';
import {
  CandidateType,
  CategoryType,
  CityType,
  FilterType,
  PaginationType,
  SelectType,
  SortType,
  VacancyType,
} from 'utils/types';

const path = '/vacancy';

type ParamsType = {
  sort?: SortType;
  filter: FilterType;
  pagination: PaginationType;
};

type NewDataType = {
  category: CategoryType;
  category_id: number;
  city: CityType;
  city_id: number;
  company: SelectType;
  country: SelectType;
  country_id: number;
  currency: SelectType;
  currency_id: number;
  description: string;
  duties: string;
  interviewStages: string;
  presentation: string | null;
  publicationPeriod: string;
  salary: number;
  status: string;
  subcategory: SelectType;
  tags: SelectType[];
  vacancyName: string;
  workingConditions: string;
};

export const getList = (
  params: ParamsType & { minimised?: boolean },
): Promise<{ data: VacancyType[]; count: number }> =>
  axios.get(path, {
    params: {
      ...params.sort,
      ...params.pagination,
      filter: {
        companies: params.filter.companies,
        country: params.filter.country,
        city: params.filter.city,
        category: params.filter.category,
        subcategory: params.filter.subcategory,
        salary: params.filter.salary,
        currency: params.filter.currency,
        status: params.filter.status,
        tags: params.filter.tags,
        search: params.filter.search,
        manager: params.filter.manager,
      },
      minimised: params.minimised,
    },
  });

export const create = (newData: NewDataType): Promise<VacancyType> => {
  const { presentation, ...rest } = newData;

  const payload = JSON.stringify(rest);
  const data = new FormData();

  data.append('data', payload);
  data.append('presentation', presentation as string | Blob);

  return axios.post(path, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const update = (
  id: number,
  newData: NewDataType,
): Promise<VacancyType> => {
  const data = new FormData();

  const { presentation, ...rest } = newData;

  const payload = JSON.stringify(rest);

  data.append('data', payload);
  data.append('presentation', presentation as string | Blob);

  return axios.patch(`${path}/update/${id}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const deleteOne = (id: number): Promise<void> =>
  axios.delete(`${path}/${id}`);

export const getOne = (id: number, minimised?: boolean): Promise<VacancyType> =>
  axios.get(`${path}/${id}`, {
    params: {
      minimised,
    },
  });

export const canOpen = (id: number): Promise<boolean> => axios.get(`${path}/${id}/can-open`);

export const getVacancyCandidates = (
  id: number,
  pagination: PaginationType,
): Promise<{ data: CandidateType[]; count: number }> =>
  axios.get(`${path}/${id}/candidates`, {
    params: {
      ...pagination,
    },
  });

export const share = (
  id: number | null,
  userId: number[],
  sharedById: number,
): Promise<VacancyType> =>
  axios.post(`${path}/share`, {
    id,
    userId,
    sharedById,
  });

export const unshare = (vacancyId: number, userId: number[]) =>
  axios.delete(`${path}/unshare/${vacancyId}/${userId}`);

const vacancyApi = {
  getList,
  getOne,
  create,
  update,
  deleteOne,
  share,
  unshare,
  getVacancyCandidates,
  canOpen,
};

export default vacancyApi;
