import axios from 'api/axios';
import {
  FilterType,
  PaginationType,
  SortType,
  EmployeesType,
  RecruiterType,
} from 'utils/types';

const path = '/employee';

type ParamsType = {
  companyId: number | null;
  sort?: SortType;
  filter: FilterType;
  pagination?: PaginationType;
};

const getList = (
  params: ParamsType,
): Promise<{ data: EmployeesType[]; count: number }> =>
  axios.get(path, {
    params: {
      ...params.sort,
      ...params.pagination,
      companyId: params.companyId,
      filter: {
        team: params.filter.team,
        manager: params.filter.manager,
        search: params.filter.search,
        status: params.filter.status,
      },
    },
  });

const create = (data: any): Promise<EmployeesType> => axios.post(path, data);

const assignToCompany = (data: {
  userId: number;
  companyId: number;
}): Promise<EmployeesType> => axios.post(`${path}/assign-company`, data);

const update = (id: number, data: any): Promise<EmployeesType> =>
  axios.put(path, data);

const deleteOne = (id: number): Promise<EmployeesType> =>
  axios.delete(`${path}/${id}`);

const getOne = (id: number): Promise<EmployeesType> =>
  axios.get(`${path}/${id}`);

const getVacancyVisibleForList = (vacancyId: number): Promise<{
  employees: EmployeesType[], recruiters: RecruiterType[],
}> =>
  axios.get(`${path}/visible-for`, {
    params: {
      vacancyId,
    },
  });

const getListOfEmployeeForVisibility = (companyId: number): Promise<{
  employees: EmployeesType[], recruiters: RecruiterType[],
}> =>
  axios.get(`${path}/visibility-list`, {
    params: {
      companyId,
    },
  });

const getListOfEmployeeCompanies = (userId: number): Promise<EmployeesType[]> =>
  axios.get(`${path}/${userId}/companies`);

const deleteStaff = (data: {
  userId: number;
  companyId: number;
}): Promise<any> => axios.delete(`${path}/${data.userId}/${data.companyId}`);

const employeeApi = {
  getList,
  create,
  deleteOne,
  update,
  getOne,
  getVacancyVisibleForList,
  getListOfEmployeeForVisibility,
  assignToCompany,
  getListOfEmployeeCompanies,
  deleteStaff,
};

export default employeeApi;
