import countriesEn from './countriesEn';
import citiesEn from './citiesEn';

const en = {
  ...countriesEn,
  ...citiesEn,
  vacancies: 'Vacancies',
  edit: 'Edit',
  'create vacancy': 'Create vacancy',
  search: 'Search',
  filter: 'Filter',
  'reset filter': 'Reset filter',
  loading: 'Loading...',
  candidates: 'Candidates',
  clients: 'Clients',
  managers: 'Managers',
  recruiters: 'Recruiters',
  agents: 'Agents',
  'hr directors': 'HR Directors',
  teams: 'Teams',
  'my teams': 'My teams',
  reports: 'Reports',
  'my reports': 'My reports',
  'recruiters reports': 'Recruiters reports',
  'weekly reports': 'Weekly reports',
  analytics: 'Analytics',
  finance: 'Finance',
  chat: 'Chat',
  Active: 'Active',
  Hold: 'Hold',
  Published: 'Published',
  Closed: 'Closed',
  'company name': 'Company name',
  'company website': 'Company website',
  'contact person name': 'Contact person name',
  'contact person': 'Contact person',
  email: 'Email',
  messenger: 'Messenger',
  nickname: 'Nickname',
  'vacancy working conditions': 'Working conditions',
  'working conditions': 'Terms of payment',
  'working conditions title': 'Working conditions',
  'working conditions first part': 'First part',
  'working conditions first part placeholder': '80% upon exit',
  'working conditions second part': 'Second part',
  'working conditions second part placeholder':
    '20% after the trial period (90 days)',
  'first part': 'first part',
  'second part': 'second part',
  'add messenger': 'Add messenger',
  manager: 'Manager',
  annual: 'Annual percentage',
  'contact person position': 'Contact person position',
  phone: 'Phone',
  'company logo': 'Company logo',
  'workspace photos': 'Workspace photos',
  'company photos': 'Company photos',
  'rest zone photos': 'Rest zone photos',
  'team photos': 'Team photos',
  'a video link': 'A video link',
  companies: 'Companies',
  'new company': 'New company',
  save: 'Save',
  publicate: 'Publicate',
  cancel: 'Cancel',
  create: 'Create',
  delete: 'Delete',
  country: 'Country',
  city: 'City',
  category: 'Category',
  subcategory: 'Subcategory',
  currency: 'Currency',
  'publication period': 'Publication period',
  'new vacancy': 'New vacancy',
  'vacancy title': 'Vacancy title',
  duties: 'Duties',
  salary: 'Salary',
  'salary shortcut': 'Salary',
  'interview stages': 'Interview stages',
  company: 'Company',
  'vacancy description': 'Vacancy description',
  'maximum 5000 characters': 'Maximum 5000 characters',
  keywords: 'Keywords / tags',
  'add presentation': '+ Add presentation',
  presentation: 'Presentation',
  page: 'Page',
  next: 'Next',
  previous: 'Previous',
  'sort by': 'Sort by:',
  published: 'Published',
  'go to full description': 'Go to full description',
  'the name': 'Name',
  location: 'Location',
  status: 'Status',
  mth: 'mth.',
  from: 'from',
  to: 'to',
  apply: 'Apply',
  tags: 'Tags',
  reset: 'Reset',
  return: 'Return',
  'photos of the workplace': 'Photos of the workplace',
  'photos of the recreation area': 'Photos of the recreation area',
  'photos of the team': 'Photos of the team',
  checklist: 'Сheck-list',
  'date of publication': 'Date of publication',
  'vacancy presentation': 'Vacancy presentation',
  vacancy: 'Vacancy',
  'up to': 'up to',
  'add client': 'add client',
  latest: 'Latest',
  oldest: 'Oldest',
  'alphabet (A-Z)': 'Alphabet (A-Z)',
  'alphabet (Z-A)': 'alphabet (Z-A)',
  'most applied': 'Most applied',
  'least applied': 'Least applied',
  'name (A-Z)': 'Name (A-Z)',
  'name (Z-A)': 'Name (Z-A)',
  password: 'Password',
  'confirm password': 'Confirm password',
  'max one': 'Maximum one ',
  'max five': 'Maximum five',
  details: 'Billing details',
  'must be one hundred': 'It must be 100 per cent in total',
  contract: 'Contract',
  'add video link': 'Add YouTube video link',
  'add contract': 'Add contract',
  'per month': 'per month',
  'company did not upload workplace photos':
    'Company did not upload workplace photos',
  'company did not upload photos of the recreation area':
    'Company did not upload photos of the recreation area',
  'company did not upload team photos': 'Company did not upload team photos',
  'company hasn’t uploaded the video yet':
    'Сompany hasn’t uploaded the video yet',
  'are you sure you want to delete the company':
    'Are you sure you want to delete the company: ',
  'are you sure you want to delete the vacancy':
    'Are you sure you want to delete the vacancy: ',
  'are you sure you want to delete the recruiter':
    'Are you sure you want to delete the recruiter: ',
  'are you sure you want to delete the hrd':
    'Are you sure you want to delete the HR director: ',
  'are you sure you want to delete the manager':
    'Are you sure you want to delete the manager: ',
  'are you sure you want to unlink the candidate':
    'Are you sure you want to unlink the candidate: ',
  'are you sure you want to delete the team':
    'Are you sure you want to delete the team: ',
  'Enter your name': 'Enter your name',
  'Name is too long': 'Name is too long',
  'Enter company name': 'Enter company name',
  'Select a category': 'Select a category',
  'Select a subcategory': 'Select a subcategory',
  'Select a country': 'Select a country',
  'Select a city': 'Select a city',
  'Add a description': 'Add a description',
  'Description is too long': 'Description is too long',
  'Add responsibilities': 'Add responsibilities',
  'Too many characters': 'Too many characters',
  'Add interview stages': 'Add interview stages',
  'Add a salary': 'Add a salary',
  'Enter a figure of 10 characters max': 'Enter a figure of 10 characters max',
  'Add terms': 'Add terms',
  'Enter a position': 'Enter a position',
  'Select keywords': 'Select keywords',
  'Select currency': 'Select currency',
  'Select activity period': 'Select activity period',
  'Upload a presentation file': 'Upload a presentation file',
  'Max file size is 30 Mb': 'Max file size is 30 Mb',
  'Select a messenger': 'Select a messenger',
  'Enter your username': 'Enter your username',
  'Enter your email': 'Enter your email',
  'Invalid email': 'Invalid email',
  'Email is not available': 'Email is not available',
  'Enter a phone number': 'Enter a phone number',
  'Number is too long': 'Number is too long',
  'Invalid phone number': 'Invalid phone number',
  'Enter a company website': 'Enter a company website',
  'Enter a contact person': 'Enter a contact person name',
  "Enter a contact person's title": "Enter a contact person's title",
  'Assign a manager': 'Assign a manager',
  'Select terms': 'Select terms',
  'Enter annual percentage rate': 'Enter annual percentage rate',
  'Annual percentage must be a number': 'Annual percentage must be a number',
  'Select a logo': 'Select a logo',
  'Password is to short': 'Password is to short',
  'Password is to long': 'Password is to long',
  "Password's must match": "Password's must match",
  'Enter a password': 'Enter a password',
  'Enter a percentage of first payment part':
    'Enter a percentage of first payment part',
  'Enter a percentage of second payment part':
    'Enter a percentage of second payment part',
  'Max file size is 10 Mb': 'Max file size is 10 Mb',
  'Enter a requisites': 'Enter a requisites',
  'Upload a contract file': 'Upload a contract file',
  'Invalid website': 'Invalid website',
  'do you really want to delete': 'Do you really want to delete?',
  'are you sure you want to delete the staff member': 'Are you sure you want to delete the staff member {{name}}?',
  'this empoyee already works for': 'This Employee already works for {{companies}}',
  'would you like to add to company': 'Would you like to add {{employeeName}} to this company?',
  'Invalid url': 'Invalid url',
  'In progress': 'In progress',
  'InProgress': 'In progress',
  'OfferRefused': 'Offer Refused',
  'PreOffer': 'Pre-Offer',
  'client type': 'Client type',
  'client type tt': 'TT',
  'vacancy reward': 'Vacancy reward',
  'recruiter reward': 'Recruiter reward',
  'client type percent': 'Percent',
  'client type all': 'All',
  Rejected: 'Rejected',
  Offer: 'Offer',
  'offer details': 'Offer details',
  'candidate name': 'Name',
  'response data': 'Apply date',
  recruiter: 'Recruiter',
  cv: 'CV',
  experience: 'Experience',
  'best skills': 'Best skills',
  specialization: 'Specialization',
  'name or email': 'Name or email',
  'no recruiter': 'No recruiter',
  'no CV': 'no CV',
  'new candidate': 'New candidate',
  'new candidate title': 'New candidate',
  'apply latest': 'Apply latest',
  'apply oldest': 'Apply oldest',
  name: 'Name',
  'apply date': 'Apply date',
  'current workplace': 'Current workplace',
  comment: 'Comment',
  'candidate was sent to manager':
    '{{authorFullName}} ({{role}}): Candidate {{fullName}} was sent to manager',
  'candidate was sent to сlient':
    '{{authorFullName}} ({{role}}): Candidate {{fullName}} was sent to client',

  'status was changed to In progress':
    '{{authorFullName}} ({{role}}): Status {{fullName}} was changed to "in progress"',
  'status was changed to Rejected':
    '{{authorFullName}} ({{role}}): Status {{fullName}} was changed to "rejected"',
  'status was changed to Hold':
    '{{authorFullName}} ({{role}}): Status {{fullName}} was changed to "hold"',
  'status was changed to Offer':
    '{{authorFullName}} ({{role}}): Status {{fullName}} was changed to "offer"',
  'candidate was added to vacancy': 'Candidate was added to vacancy',
  'yours comment': 'Yours comment',
  'send to manager': 'Send to manager',
  'send to client': 'Send to client',
  candidate: 'Candidate',
  activity: 'Activity',
  'place of work': 'Current place of work',
  Russia: 'Russia',
  Ukraine: 'Ukraine',
  'recruiter name': "Recruiter's name",
  unlink: 'Unlink',
  'new recruiter': 'New recruiter',
  'new recruiter title': 'New recruiter',
  team: 'Team',
  'annual percentage': 'Annual percentage',
  requisites: 'Requisites',
  'reward for the listed candidates': 'reward for the listed candidates',
  'reward for the closed position': 'Reward for the closed position',
  avatar: 'Avatar',
  "bigger recruiter's commission": "Recruiter's commission rate for partner companies above 13%",
  "lower recruiter's commission": "Recruiter's commission rate for partner companies below 13%",
  "senior bigger recruiter's commission": "Senior Recruiter's commission rate for partner companies above 13%",
  "senior lower recruiter's commission": "Senior Recruiter's commission rate for partner companies below 13%",
  "subrecruiter's pay for a closed position":
    "Subrecruiter's pay for a closed position",
  'payment details': 'Payment details',
  'hr director name': "Director's name",
  'HRD Name': 'HRD Name',
  'hr directors title': 'HR directors',
  'new hr director title': 'Add HR Director',
  'assigned managers': 'Assigned managers',
  'new hr director': 'New director',
  yes: 'Yes',
  no: 'No',
  'are you sure you want to cancel': 'Are you sure you want to cancel?',
  'your account': 'Your account',
  'sign out': 'Sign out',
  'comment is too long': 'Comment is too long',
  'offer amount': 'Offer amount',
  'date of employment': 'Date of employment',
  'date of probation end': 'Date of probation end',
  active: 'Active',
  disabled: 'Disabled',
  'subrecruiter percentage': 'Subrecruiter percentage',
  'new role': 'New role',
  'hr director': 'HR director',
  hrDirector: 'HR director',
  'managers title': 'Managers',
  'new manager title': 'Add manager',
  'new manager': 'New manager',
  'manager name': "Manager's name",
  "bigger manager's commission": "Manager's commission rate for partner companies above 13%",
  "lower manager's commission": "Manager's commission rate for partner companies below 13%",
  "bigger director's commission": "Director's commission rate for partner companies above 13%",
  "lower director's commission": "Director's commission rate for partner companies below 13%",
  'teams title': 'Teams',
  "team's cash": "Team's cash",
  "team's size": "Team's size",
  'new team title': 'Add team',
  'team name': 'Team name',
  'select from the list': 'Select from the list',
  'edit team title': 'Edit team',
  'trial period end': 'Trial period end',
  total: 'Total',
  'team cash': 'Team cash',
  'add criterion': 'Add criterion',
  'must have': 'Must have',
  'must have checkbox': 'Must have',
  'optional': 'Optional',
  'mark the matches': 'Mark the matches',
  close: 'Close',
  send: 'Send',
  'no requirements': 'No requirements',
  for: 'For',
  offer: 'Offer',
  'the client did not add requirements': 'The client did not add requirements',
  invalidApplyDate: 'Invalid date',
  'wrong date': 'Wrong date',
  invalidYear: 'Year must be current year or greater than current year',
  description: 'Description',
  month: 'Month',
  'two months': 'Two months',
  'three months': 'Three months',
  year: 'Year',
  'one week': 'One week',
  '1 week': '1 week',
  '1 month': '1 month',
  '2 months': '2 months',
  '3 months': '3 months',
  'employment date': 'Employment date',
  share: 'Share',
  'new category': 'New category',
  'new subcategory': 'New subcategory',
  'category is alredy exist': 'Category is alredy exist',
  'subcategory is alredy exist': 'Subcategory is alredy exist',
  'create a new subcategory for': 'Create a new subcategory for',
  'create a new category': 'Create a new category',
  'enter a name': 'Enter a name',
  'subcategory name is too long': 'Subcategory name is too long',
  'add new category': 'Add new category',
  'add new subcategory': 'Add new subcategory',
  'you have successfully shared the vacancy':
    'You have successfully shared the vacancy',
  staff: 'Staff',
  'new employee title': 'New employee',
  'employee name': 'Employee name',
  employees: 'Employees',
  'allow Client add colleagues': 'Allow Client add colleagues/team',
  notifications: 'Notifications',
  settings: 'Settings',
  'see all': 'See all',
  'mark all as read': 'Mark all as read',
  'no notifications': 'No notifications',
  'status has been updated': 'status has been updated',
  'new comment to': 'New comment to',
  'additional information': 'Additional information',
  'checklist link': 'Checklist link',
  restore: 'Make active',
  'candidates sent to clients': 'Candidates sent to clients',
  'offers': 'Offers',
  'add comment': 'Add comment',
  'expand': 'Expand',
  'collapse': 'Collapse',
  'annual statistics': 'Annual statistics',
  'vacancy visible for client': 'Vacancy visible for client',
  'vacancy visible for recruiters': 'Recruiting team',
  'is visible for all clients': 'Is visible for all clients?',
  'is visible for all recruiters': 'Is visible for all recruiters?',
};

export default en;
